.App {
  text-align: center;
}

#flower {
  height: 90vh;
  width: auto;
  animation: img-spin infinite 20s linear;
}

@keyframes img-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
